<template>
  <div ref="megaMenuRef" class="f-col overflow-hidden py-8 c-brand-2" style="min-height: 100dvh">
    <nav :aria-label="$t.primaryNavigationLabel" class="f-col grow">
      <ul
        class="flex gap-1 overflow-x-auto px-6 pb-1 pt-4 scrollbar-sm duration"
        :class="{ 'op-0 invisible': activeL2.id }"
      >
        <li v-for="l1 in items.children" :key="l1.id">
          <base-button
            :aria-current="l1.id === activeL1.id"
            class="ws-nowrap b b-transparent rounded-sm px-1 subtitle-4 duration @hactive:b-white"
            :class="{ 'bg-white c-brand-1': l1.id === activeL1.id }"
            @click="clickL1(l1)"
          >
            {{ l1.title }}
          </base-button>
        </li>
      </ul>
      <ul class="relative mt-4" :style="`padding-top: ${pxToRem(inset)}`">
        <li v-for="(l1, i) in items.children" :key="i">
          <a class="hidden" :href="l1.target?.url">
            {{ l1.title }}
          </a>
          <transition name="slide">
            <ul v-show="l1.id === activeL1?.id" :id="l1.id" class="slide-left absolute top-0 w-full px-6">
              <template v-if="l1.columns?.length">
                <template v-for="(column, colKey) in l1.columns" :key="colKey">
                  <li v-for="(l2, l2Key) in column.children" :key="l2Key">
                    <component
                      :is="l2.target ? 'a' : 'button'"
                      v-if="l2.children?.length"
                      class="group w-full flex items-center gap-2 py-2 between"
                      :class="{ 'op-0 invisible': activeL2.id }"
                      :href="l2.target?.url"
                      rel="noopener nofollow"
                      @click="clickL2(l2, $event)"
                    >
                      <span
                        class="group-hover:-rotate-3"
                        :style="{ 'transition-duration': `${SLANT_TRANSITION_DURATION}ms` }"
                      >
                        <span class="body-3 underscore">
                          {{ l2.title }}
                        </span>
                      </span>
                      <vf-icon name="chevron" size="md" />
                    </component>
                    <base-link
                      v-else
                      class="group w-full flex py-2"
                      :class="{ 'op-0 invisible': activeL2.id }"
                      :to="l2.target?.url"
                      @click="(e) => clickL2(l2, e)"
                    >
                      <span
                        class="group-hover:-rotate-3"
                        :style="{ 'transition-duration': `${SLANT_TRANSITION_DURATION}ms` }"
                      >
                        <span class="body-3 underscore">
                          {{ l2.title }}
                        </span>
                      </span>
                    </base-link>
                    <template v-if="l2.children?.length">
                      <transition name="slide">
                        <ul v-show="activeL2.id === l2.id" class="slide-left absolute left-0 top-0 z-1 w-full px-8">
                          <li>
                            <base-button
                              v-show="activeL2.id"
                              class="group mb-6 flex items-center gap-2 <md:w-full"
                              style="margin-top: -3.055rem"
                              @click="activeL2 = {}"
                            >
                              <vf-icon dir="left" name="chevron" />
                              <span class="sr-only">{{ $t.backTo }}</span>
                              <span class="subtitle-4 underscore">{{ activeL2.title }}</span>
                            </base-button>
                          </li>
                          <li :class="{ '<lg:hidden': activeL2.hideShopAll?.sm, 'lg:hidden': activeL2.hideShopAll?.lg }">
                            <base-link
                              class="group w-full flex py-2"
                              :to="activeL2.target?.url"
                              @click="emit('close')"
                            >
                              <span
                                class="group-hover:-rotate-3"
                                :style="{ 'transition-duration': `${SLANT_TRANSITION_DURATION}ms` }"
                              >
                                <span class="body-3 underscore">
                                  {{ $t.viewAll }}
                                </span>
                              </span>
                            </base-link>
                          </li>
                          <li v-for="(l3, l3Key) in l2.children" :key="l3Key">
                            <base-link class="group w-full flex py-2" :to="l3.target?.url" @click="clickL3(l3)">
                              <span
                                class="group-hover:-rotate-3"
                                :style="{ 'transition-duration': `${SLANT_TRANSITION_DURATION}ms` }"
                              >
                                <span class="body-3 underscore">
                                  {{ l3.title }}
                                </span>
                              </span>
                            </base-link>
                          </li>
                        </ul>
                      </transition>
                    </template>
                  </li>
                </template>
              </template>
            </ul>
          </transition>
        </li>
      </ul>
      <ul
        class="f-col grow items-start justify-end gap-5 px-6"
        :class="activeL2.id ? 'invisible max-h-0 op-0' : 'mt-12 duration'"
      >
        <li
          v-for="({ icon, to, title }, key) in collections"
          :key
        >
          <base-link class="c-grey-60 duration @hactive:c-white" :to @click="$gtm.push('topNav.onClickTopNavLink', title)">
            <span class="sr-only">{{ title }}</span>
            <vf-icon v-bind="icon" />
          </base-link>
        </li>
        <li v-if="useCountryCode() === 'US'">
          <base-link class="c-grey-60 duration @hactive:c-white" :to="otwCategoryUrl" @click="$gtm.push('topNav.onClickTopNavLink', 'OTW')">
            <span class="sr-only">OTW</span>
            <vf-icon height="22" src="/img/logos/vans/otw.svg" width="62" />
          </base-link>
        </li>
      </ul>
    </nav>
    <vf-utility-navigation
      v-if="links"
      class="body-4 mt-12 px-6"
      :class="activeL2.id ? 'invisible max-h-0 op-0' : 'duration'"
      :links
      show-loyalty
      @click="emit('close')"
      @sign-in="emit('signIn')"
    />
    <vf-button
      :aria-label="$t.close"
      class="pointer-events-auto fixed bottom-0 right-0 m-8 rounded-sm p-3 duration <lg:hidden"
      data-test-id="vf-panel-close"
      style="z-index: 10001"
      variant="primary-inverse"
      @click="emit('close')"
    >
      <vf-icon name="close" size="lg" style="margin: -0.355rem" />
    </vf-button>
  </div>
</template>

<script lang="ts" setup>
import { BaseButton, BaseLink } from '#components'
import type { MegaMenuContent, MenuItem } from '#types/components/cms/mega-menu'
import type { NavigationLink } from '#types/navigation'
import type { RecursivePartial } from '#types/utils'

const { items } = defineProps<{
  items: MegaMenuContent
  links?: NavigationLink[]
}>()

const emit = defineEmits<{
  close: []
  signIn: []
}>()

const auth = useAuthStore()
const { otwCategoryUrl } = useFeatureFlags()
const loyalty = useLoyaltyStore()
const { $t, $gtm, $viewport } = useNuxtApp()
const collections = await useApi().internal.getCollections().data

const activeL1 = ref<RecursivePartial<MenuItem>>(items.children[0]!)
const activeL2 = ref<RecursivePartial<MenuItem>>({})
const megaMenuRef = ref<HTMLElement>()

const SLANT_TRANSITION_DURATION = 200

const inset = computed(() => {
  // 40 is the height of each L1 menu item
  // 41 is the height of each L2 menu item
  // +1 for the view all link in L2
  if (activeL2.value?.children?.length) return (activeL2.value.children.length + 1) * 41
  else return (activeL1?.value?.columns?.reduce((acc, col) => acc + (col?.children?.length || 0), 0) || 0) * 40
})

const clickL1 = (l1: MenuItem) => {
  activeL1.value = l1
  $gtm.push('topNav.onClickMobileMenuSection', fullCapitalize(l1.title))
}

const clickL2 = async (l2: MenuItem, e: MouseEvent) => {
  if (l2.children?.length) {
  // Prevent default behavior only when ctrl or cmd key is not pressed
    if (!(e.ctrlKey || e.metaKey)) {
      e.preventDefault()

      // When on mobile, the brand wants the tilted animation to happen, so we hold the transition for the time of the animaiton
      if (!$viewport.lg) await wait(SLANT_TRANSITION_DURATION)

      activeL2.value = l2
    }
    $gtm.push('topNav.onClickMobileMenuSection', fullCapitalize(`${activeL1.value.title} - ${l2.title}`))
  }
  else { $gtm.push('topNav.onClickTopNavLink', fullCapitalize(`${activeL1.value.title} - ${l2.title}`)) }
}

const clickL3 = (l3: MenuItem) => {
  $gtm.push('topNav.onClickTopNavLink', fullCapitalize(`${activeL1.value.title} - ${activeL2.value.title} - ${l3.title}`))
  emit('close')
}

useSwipe(megaMenuRef, {
  onSwipeEnd(_, dir) {
    if (dir === 'right' && activeL2.value.id) activeL2.value = {}
  }
})

onMounted(() => {
  if (auth.loggedIn && !loyalty?.vouchers) loyalty.getVouchers()
})
</script>
